import { createContext, useState, useContext, ReactNode } from 'react';
import { createTheme, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
}
declare module '@mui/material/styles' {
  interface Palette {
    row_background: {
      blue: string;
      yellow: string;
      grey: string;
    };
  }
  interface PaletteOptions {
    row_background?: {
      blue?: string;
      yellow?: string;
      grey?: string;
    };
  }
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState(createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#1976d2', // Custom primary color
        dark: '#0056b3', // Custom dark color for primary
      },
      secondary: {
        main: '#0c1e67', // Custom secondary color
        // '#0c1e67'
        //'#d27619'
      },
      row_background: {
        blue: '#ebf5fb',
        yellow: 'lightyellow',
        grey: '#efefef'
      }
    },
  }));

 // "#efefef" : "#ebf5fb"


  const toggleTheme = () => {
    setTheme((prevTheme) => createTheme({
      palette: {
        mode: prevTheme.palette.mode === 'light' ? 'dark' : 'light',
      },
    }));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);