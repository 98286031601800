import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<StrictMode>
      <App />
    //</StrictMode>
);
} else {
  console.error("Root element with id 'root' not found.");
}

// reportWebVitals();
