import { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { SnackbarAlertType, SnackbarAlertDefaults } from "../types/Alert";

interface AlertWithId extends SnackbarAlertType {
  id: string;
}

export interface AlertContextType {
  setAlert: (newAlert: SnackbarAlertType) => void;
}

export const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider = ({ children }: { children: ReactNode }) => {

  const setAlert = useCallback((newAlert: SnackbarAlertType) => {
    if (!newAlert) return;
    enqueueSnackbar(newAlert.message, { variant: newAlert.type, autoHideDuration: newAlert.duration ?? SnackbarAlertDefaults.duration });
  }, []);


  return (
    <AlertContext.Provider value={{ setAlert }}>
      <SnackbarProvider dense maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} />
      {children}
    </AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within an AlertProvider');
  }
  return context;
};