export const CertSettingTypeDefaults = {
    check_in: { max: 0, min: 0 },
    completion: { max: 0, min: 0 },
    kickoff: { max: 0, min: 0 },
    office_hours: { max: 0, min: 0 },
    program_eval: false,
    reflection: { max: 0, min: 0 },
};
;
export const CertTypeDefaults = {
    check_in: 0,
    completion: 0,
    kickoff: 0,
    office_hours: 0,
    program_eval: false,
    reflection: 0
};
