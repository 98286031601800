export interface SnackbarAlertType {
  open: boolean;
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  duration?: number;
};

export const SnackbarAlertDefaults: SnackbarAlertType = {
  open: false,
  type: 'info',
  message: '',
  duration: 5000
};